<template lang="pug">
	div.page-offer-form

		loading(:active.sync="busy", :is-full-page="true")

		form(@submit.prevent="submit", v-if="offer")

			.widget
				.widget-header
					h2.title Offer Default Settings
					.buttons.pos-right
						router-link.btn.btn-default(:to="{name:'offer-list'}")
							i.la.la-angle-left
							| Back
						button.btn.btn-primary.ml-2(type="submit") Save
							i.la.la-fw.la-save

			offer-defaults(:offer="offer")
</template>
<style lang="scss">
.multi-inputs {
  & > .input-group-wrapper,
  & > .form-control {
    width: 48%;
    margin: 0 1%;
    display: inline-block;
  }
}

.custom-checkbox {
  &.cstatus-live {
    color: #43bc41;
  }

  &.cstatus-paused {
    color: #2c529b;
  }
}

table.table-macros {
  thead {
    tr {
      th {
        padding: 2px 2px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 2px 2px;
      }
    }
  }
}

.page-offer-form {
  .app-icon-container {
    width: 120px;
    height: 120px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    background: #ccc;
    border-radius: 20px;

    img {
      width: 120px;
      height: 120px;
      border-radius: 20px;
    }
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'OfferDefaultSettings',
  async beforeRouteEnter(to, from, next) {
    let offer = {};
    try {
      offer = await Vue.ovData.offer.getDefaults(true);
    } catch (e) {
      Vue.ovNotify.error('Failed to load settings');
      return next(false);
    }

    Vue.ovData.offer._booleans.forEach((f) => {
      offer[f] = !!offer[f];
    });

    next((vm) => {
      vm.offer = offer;
      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      busy: false,
      offer: null,
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.offer);

      Vue.ovData.offer._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });

      try {
        await this.$ovReq.post('settings/saveOfferDefaults', data);
        this.busy = false;
        this.$ovNotify.success('Offer Defaults has been saved');
        this.$router.push({ name: 'offer-list' });
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },
  },
};
</script>
